import React, {useEffect} from 'react';
/**
 *
 *
 * @return {*}
 */
const Hcd = () => {
  useEffect(() => {
    window.top.postMessage('hcd_selected', '*');
  }, []);


  return (
    <>
      <div></div>
    </>
  );
};

export default Hcd;
